<template>
  <div
    class="flex h-screen bg-gray-50 dark:bg-surface-900"
    :class="{ 'overflow-hidden': isOpen }"
  >
    <Sidebar ref="sidebarRef" v-on-click-outside="shouldCloseSidebar" />
    <div class="flex overflow-x-hidden flex-col flex-1 w-full">
      <NavigationMenu ref="navigationMenuRef" HasToggleableMenu />
      <main class="overflow-y-auto h-screen bg-gray-100 dark:bg-surface-900">
        <slot name="header" />
        <div class="container max-w-full min-h-screen">
          <slot />
        </div>
        <Footer />
      </main>
    </div>
  </div>
</template>
<script setup>
import Sidebar from "~/components/sidebar.vue";
import { useSidebarStore } from "~/stores/sidebar";
import { vOnClickOutside } from "@vueuse/components";
import NavigationMenu from "~/components/navigationMenu.vue";
import { downloadFromUrls } from "~/services/downloader";

const sidebarStore = useSidebarStore();
const sidebarRef = ref(null);
const navigationMenuRef = ref(null);
const { $echo, $eventBus } = useNuxtApp();
const user = useSanctumUser()?.value;
const { isOpen } = storeToRefs(sidebarStore);
const { closeSidebar } = sidebarStore;

function shouldCloseSidebar(event) {
  if (
    event.target !== navigationMenuRef.value &&
    !navigationMenuRef.value.hamburgerMenuRef.contains(event.target)
  ) {
    closeSidebar();
  }
}

$echo
  .private(`users.${user.global_id}`)
  .listen(".pdf.generation.success", (e) => {
    $eventBus.emit("toast", {
      severity: "success",
      unique: true,
      group: "export-pdf-invoice-status",
      summary: "Export des factures en pdf réussi",
      life: 2000,
    });
    onNuxtReady(async () => await downloadFromUrls(e.data));
  })
  .listen(".pdf.generation.failed", (e) => {
    $eventBus.emit("toast", {
      severity: "error",
      unique: true,
      group: "export-pdf-invoice-status",
      summary: e.message,
      life: 10000,
    });
  })
  .listen(".pdf.email-delivery.success", (e) => {
    $eventBus.emit("toast", {
      severity: "success",
      unique: true,
      group: "export-pdf-email-delivery-status",
      summary: e.message,
      life: 3000,
    });
    $eventBus.emit("email-deliver-status", {});
  })
  .listen(".pdf.email-delivery.failed.partially", (e) => {
    $eventBus.emit("toast", {
      severity: "error",
      summary: "erreur",
      detail: e.reason,
    });
    $eventBus.emit("email-deliver-status", {});
  })

  .listen(".pdf.email-delivery.failed", (e) => {
    $eventBus.emit("toast", {
      unique: true,
      severity: "error",
      group: "export-pdf-email-delivery-status",
      summary: "erreur",
      detail: e.reason,
    });
    $eventBus.emit("email-deliver-status", {});
  });
</script>
