<template>
  <div class="w-full">
    <span
      v-if="selected"
      class="absolute inset-y-0 left-0 w-1 bg-primary-900 rounded-tr-lg rounded-br-lg"
      aria-hidden="true"
    ></span>
    <template v-if="props.new && !isNaN(Number(item.key))">
      <OverlayBadge severity="success" >
      <NuxtLink
        @click="onItemClick()"
        :data-menu-item="item.label.toLowerCase()"
        :class="{ 'dark:text-gray-200': props.selected }"
        class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 cursor-pointer dark:text-gray-100 dark:hover:text-gray-200"
        :to="item.href"
      >
        <component
        v-if="item.icon != undefined || item.icon != null"
        :is="'i-' + item.icon"
        class="w-6 h-6"
        ></component>
        <div v-else class="w-6 h-6"></div>
        <span
        v-if="item.label != undefined"
        v-text="item.label"
        class="ml-4"
        ></span>
        <button
        v-if="item.items && item.items.length > 0"
        class="inline-flex justify-between items-center ml-auto w-full text-sm font-semibold transition-colors duration-150 max-w-fit dark:hover:text-gray-200"
        aria-haspopup="true"
        >
        <svg
          v-if="isActive"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
        </button>
      </NuxtLink>
      </OverlayBadge>
    </template>
    <template v-else>
      <NuxtLink
      @click="onItemClick()"
      :data-menu-item="item.label.toLowerCase()"
      :class="{ 'dark:text-gray-200': props.selected }"
      class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 cursor-pointer dark:text-gray-100 dark:hover:text-gray-200"
      :to="item.href"
      >
      <component
        v-if="item.icon != undefined || item.icon != null"
        :is="'i-' + item.icon"
        class="w-6 h-6"
      ></component>
      <div v-else class="w-6 h-6"></div>
      <Badge severity="success" v-if="props.new" ></Badge>
      <span
        v-if="item.label != undefined"
        v-text="item.label"
        class="ml-4"
      ></span>
      <button
        v-if="item.items && item.items.length > 0"
        class="inline-flex justify-between items-center ml-auto w-full text-sm font-semibold transition-colors duration-150 max-w-fit dark:hover:text-gray-200"
        aria-haspopup="true"
      >
        <svg
        v-if="isActive"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4"
        >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m19.5 8.25-7.5 7.5-7.5-7.5"
        />
        </svg>
        <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4"
        >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m8.25 4.5 7.5 7.5-7.5 7.5"
        />
        </svg>
      </button>
    </NuxtLink>
    </template>
    
  </div>
</template>

<script setup lang="ts">
import { Badge, OverlayBadge } from "primevue";
import type { MenuItem } from "primevue/menuitem";

const emit = defineEmits(["update:selected"]);
const props = defineProps<{
  item: MenuItem;
  isActive: boolean | undefined;
  selected: boolean;
  new?: boolean;
}>();
function onItemClick() {
  emit("update:selected", { href: props.item.href, key: props.item.key });
  if (props.item?.action) {
    props.item?.action();
  }
}
</script>
