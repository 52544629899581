<template>
  <transition
    enter-active-class="transition duration-150 ease-in-out"
    enter-from-class="opacity-0 transform-translate-x-20"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-150 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 transform -translate-x-20"
  >
    <aside
      v-show="isOpen"
      class="hidden overflow-y-auto z-20 flex-shrink-0 w-64 bg-primary-500 border-r border-gray-200 md:block dark:bg-primary-800"
      tabindex="0"
      @keyup.esc="closeSidebar"
    >
      <div class="pb-4 text-gray-500 dark:text-gray-400">
        <div class="flex gap-4 items-center py-4">
          <NuxtLink
            class="ml-6 text-lg font-bold text-gray-200 dark:text-gray-200"
            to="/"
          >
            OCV
          </NuxtLink>
          <ColorSchemeSwitcher />
        </div>
        <PanelMenu
          v-model:expandedKeys="expandedKeys"
          :model="menuItems"
          @panel-open="onPanelOpen"
          @panel-close="onPanelClose"
          :pt="{
            headerContent: {
              class: 'bg-transparent text-white ',
            },
            panel: {
              class: 'relative px-6 py-3 border-none bg-transparent dark:bg-transparent hover:bg-gray-100/10',
            },
            rootList: {
              class:
                'p-2 mx-auto space-y-2 overflow-hidden text-sm font-medium rounded-md border-none shadow-inner bg-primary-600 dark:text-gray-400 dark:bg-primary-900',
            },
            menuItem: {
              class: 'px-2 py-1',
            },
            content: {
              class: '',
            },
            itemContent: {
              class:
                'transition-colors duration-150 text-gray-200 hover:bg-gray-100/10 dark:hover:text-gray-200',
            },
          }"
        >
          <template #item="{ item, active }">
            <SidebarItem
              :item="item"
              :ref="(el) => item && item.key && (panelItemsRef[item.key] = el)"
              :isActive="active"
              :selected="selectedItem?.key == item.key"
              :new="item.new"
              @update:selected="onItemSelected"
            />
          </template>
        </PanelMenu>
      </div>
    </aside>
  </transition>
</template>

<script setup lang="ts">
import Role from "~/types/Role";
import Permissions from "~/types/Permissions";
const sidebarStore = useSidebarStore();
const userPermissions = usePermissions();
const userRoles = useRoles();
const expandedKeys = ref({});
const panelItemsRef = shallowReactive<{ [key: string]: any }>({});
const { isOpen } = storeToRefs(sidebarStore);
const { closeSidebar } = sidebarStore;
const selectedItem = shallowRef({});
interface Node {
  key: string;
  items: Node[];
}
function onPanelOpen(item: { item: string }) {
  sidebarStore.addOpenedPanel(item.item);
}

function onPanelClose(item: { item: string }) {
  sidebarStore.removeOpenedPanel(item.item);
}

const expandNode = (
  node: Node,
  expandedKeys: { value: { [key: string]: boolean } },
) => {
  if (node.items && node.items.length) {
    expandedKeys.value[node.key] = true;
    for (let child of node.items) {
      expandNode(child, expandedKeys);
    }
  }
};
const filterMenuItemsByAccessRights = (
  menuItems: any[],
  userRolesWithPermissions: { roles: string[]; permissions: string[] },
) => {
  return menuItems.reduce((newMenuItem, currentMenuItem) => {
    const shouldCheckRoleAccess = currentMenuItem.roles != undefined;
    const { roles: userRoles, permissions: userPermissions } =
      userRolesWithPermissions;
    const rolesByPassingAll = [Role.SUPER_ADMIN];
    const userHasAllRight =
      rolesByPassingAll?.filter((role) => userRoles.includes(role))?.length > 0;
    const shouldCheckPermissionAccess =
      currentMenuItem.permissions != undefined;
    if (
      (!shouldCheckPermissionAccess && !shouldCheckPermissionAccess) ||
      userHasAllRight
    ) {
      newMenuItem.push(currentMenuItem);
      return newMenuItem;
    }

    if (shouldCheckRoleAccess) {
      const userHasMatchingRoles =
        currentMenuItem?.roles?.filter((role) => userRoles.includes(role))
          ?.length > 0;
      if (userHasMatchingRoles) {
        if (currentMenuItem.items) {
          currentMenuItem.items = filterMenuItemsByAccessRights(
            currentMenuItem.items,
            userRolesWithPermissions,
          );
        }

        newMenuItem.push(currentMenuItem);
        return newMenuItem;
      }
    }
    if (shouldCheckPermissionAccess) {
      const userHasMatchingPermissions =
        currentMenuItem?.permissions?.filter((permission) =>
          userPermissions.includes(permission),
        )?.length > 0;
      if (userHasMatchingPermissions) {
        if (currentMenuItem.items) {
          currentMenuItem.items = filterMenuItemsByAccessRights(
            currentMenuItem.items,
            userRolesWithPermissions,
          );
        }
        newMenuItem.push(currentMenuItem);
        return newMenuItem;
      }
    }
    return newMenuItem;
  }, []);
};

onMounted(() => {
  sidebarStore.getOpenedPanels.forEach((item: Node) => {
    expandNode(item, expandedKeys);
  });
  selectedItem.value = sidebarStore.getSelectedItem();
  const userAccessRight = {
    roles: userRoles.value,
    permissions: userPermissions.value,
  };
  menuItems.value = filterMenuItemsByAccessRights(
    menuItems.value,
    userAccessRight,
  );
});
async function onItemSelected(item: { href: string; key: string }) {
  selectedItem.value = item;
  sidebarStore.setSelectedItem(item);
}
const menuItems = ref([
  {
    key: "0",
    label: "Tableau de bord",
    href: "/",

    icon: "dasboard-icon",
    param: "",
  },
  {
    key: "1",
    label: "Villes",
    href: "/cities",
    roles: [Role.ADMIN],
    permissions: [Permissions.ViewCity],
    icon: "location-icon",
  },
  {
    key: "2",
    label: "Les marchés",
    href: "/markets",
    roles: [Role.ADMIN],
    permissions: [Permissions.ViewMarkets],
    icon: "shopping-icon",
  },
  {
    key: "3",
    label: "Placiers",
    roles: [Role.ADMIN],
    permissions: [Permissions.ViewPlacers],
    items: [
      {
        key: "3_1",
        label: "Placiers",
        permissions: [Permissions.ViewPlacers],
        href: "/placers",
      },
      {
        key: "3_2",
        label: "Appareils",
        permissions: [Permissions.ViewPlacers],
        href: "/materials",
        new: true,
      },
    ],
    icon: "user-icon",
    new: true
  },
  {
    key: "4",
    label: "Commerçants",
    permissions: [
      Permissions.ViewMerchants,
      Permissions.ViewSubscriptions,
      Permissions.ViewRenewals,
    ],
    items: [
      {
        key: "4_1",
        label: "Entreprises",
        href: "/companies",
      },
      {
        key: "4_2",
        label: "Commerçants",
        permissions: [Permissions.ViewMerchants],
        href: "/merchants",
      },
      {
        key: "4_3",
        label: "Abonnements",
        href: "/subscriptions",
        permissions: [Permissions.ViewSubscriptions],
        new: true
      },
      {
        key: "4_4",
        label: "Reconductions",
        permissions: [Permissions.ViewRenewals],
        href: "/renewals",
        new: true
      },
      {
        key: "4_5",
        label: "Exceptions de reconduction",
        href: "/subscriptions/renewal_exceptions",
        roles: [Role.ADMIN, Role.SUPER_ADMIN],
        permissions: [Permissions.ViewRenewalExceptions],
      },
    ],
    icon: "user-group-icon",
    new: true
  },
  {
    key: "5",
    label: "Facturation",
    icon: "document-icon",
    permissions: [
      Permissions.ViewProducts,
      Permissions.ViewInvoices,
      Permissions.ViewPayments,
      Permissions.ViewCredits,
    ],
    items: [
      {
        key: "5_1",
        label: "Articles",
        permissions: [Permissions.ViewProducts],
        href: "/products",
      },
      {
        key: "5_2",
        label: "Factures",
        items: [
          {
            key: "5_2_1",
            label: "Émises",
            permissions: [Permissions.ViewInvoices],
            href: "/invoices",
            new: true
          },
          {
            key: "5_2_2",
            label: "Offres, brouillons et archives",

            permissions: [Permissions.ViewInvoices],
            href: "/invoices/noactive-invoices",
            new: true
          },
        ],
        permissions: [Permissions.ViewInvoices],
        new: true
      },
      {
        key: "5_3",
        label: "Paiements",

        permissions: [Permissions.ViewPayments],

        href: "/payments",
      },
      
    ],
    new: true
  },
  {
    key: "6",
    label: "Comptabilité",
    icon: "money-icon",
    permissions: [Permissions.ViewDeposits, Permissions.ViewCashregister],
    items: [
      {
        key: "6_1",
        label: "Dépôts",
        href: "/deposits",
        permissions: [Permissions.ViewDeposits],
      },
      {
        key: "6_2",
        label: "Caisses",
        href: "/cashregister",
        permissions: [Permissions.ViewCashregister],
      },
      {
        key: "6_3",
        label: "Export comptables",
        permissions: [Permissions.ExportDeposit],
        action: function () {
          console.log("export comptable");
        },
      },
    ],
  },
  {
    key: "7",
    label: "Signalements",
    icon: "bell-icon",
    permissions: [Permissions.ViewEvents],
    items: [
      {
        key: "7_1",
        label: "Signalements ouverts",

        permissions: [Permissions.ViewEvents],
        href: "/events/open",
      },
      {
        key: "7_2",
        label: "Signalements clos",
        permissions: [Permissions.ViewEvents],
        href: "/events/close",
      },
    ],
  },
  {
    key: "8",
    href: "/absences",
    icon: "bell-icon",

    label: "Absences",
  },
  {
    key: "9",
    icon: "user-icon",
    label: "Utilisateurs",
    permissions: [Permissions.ViewUsers],
    href: "/users",
  },
  {
    key: "10",
    href: "/settings",
    icon: "cog-icon",
    roles: [Role.ADMIN, Role.SUPER_ADMIN],
    permissions: [Permissions.AccessToHomeSettingPage],
    label: "Paramètres",
  },
]);
</script>
<style scoped>
:deep(.panelmenu.item.focus.color){
  color: --color-primary-500;
}
</style>
