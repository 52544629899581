export async function downloadFromUrls(data: PdfResponse) {
  for (let i = 0; i < data.urls.length; i++) {
    const { data: response, error } = await useAsyncData(() => $fetch.raw(data.urls[i].url));
    if (error.value) {
      throw error.value
    }
    const blob = new Blob([response.value!._data], { type: response.value!.headers?.get("Content-Type") ?? "application/pdf" });
    downloadFileFromBlob(blob, data.urls[i].filename);
  }
}
