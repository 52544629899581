export function downloadFileFromBlob(blob: Blob, fileName = "filename") {
  const fileUrl = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  // Revoke the object URL to free up memory
  URL.revokeObjectURL(fileUrl);
  document.body.removeChild(a);
}
